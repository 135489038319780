import { markupMenu } from './markup-menu';
markupMenu(window.document);
jcf.replaceAll();
$('.fancybox').fancybox({
  padding: 0,
    scrolling: 'no',
    helpers: {
      overlay: {
        locked: false,
        css: {
          'background': 'rgba(0,0,0,.65)'
        }
      }
    }
});
const menuBtn = document.querySelector('[data-app="menu-toggle"]');
const toggleMenu = function(){
  $(this).toggleClass('active');
}
$(menuBtn).on('click', toggleMenu);
(() => {
  const signInOpenBtn = document.querySelector('[data-app="sign-in-btn"]');
  if (!signInOpenBtn) {
    return ;
  }
  const signInModal = document.querySelector('[data-app="sign-in-modal"]')
  const toggleSignInModal = function(e){
    $(signInOpenBtn).toggleClass('active');
    $('.sign-in').toggleClass('active');
    if ($(signInOpenBtn).hasClass('active')) {
      setTimeout(()=>{
        $(signInModal).find('input[type="email"]').eq(0).focus();
      }, 50);
    }
  };
  $(signInOpenBtn).on('click', toggleSignInModal);
  $(signInModal).on('click', (e) => {
    e.stopPropagation();
  });
  $(document.body).on('click', (e) => {
    if (!($(e.target).closest('[data-app="sign-in-btn"]').length) && $(signInOpenBtn).hasClass('active')) {
      $(signInOpenBtn).removeClass('active');
      $(signInModal).removeClass('active');
    }
  })
})()
const searchOpenBtn = document.querySelector('[data-app="search-form-btn"]');
const searchForm = document.querySelector('[data-app="search-form"]');
const toggleSearchForm = function(e) {
  $(searchOpenBtn).toggleClass('active');
  $(searchForm).toggleClass('active');
  if ($(searchForm).hasClass('active')) {
    setTimeout(()=>{
      $(searchForm).find('input').trigger('focus');
    }, 100);
  } else {
    $(searchForm).find('input').val('')
  }
}
$(searchOpenBtn).on('click', toggleSearchForm);
$(searchForm).on('click', (e) => {
  e.stopPropagation();
})
$(document.body).on('click', (e) => {
  if (!($(e.target).closest('[data-app="menu-toggle"]').length) && $(menuBtn).hasClass('active')) {
    $(menuBtn).removeClass('active')
  }
  if (!($(e.target).closest('[data-app="search-form-btn"]').length) && $(searchOpenBtn).hasClass('active')) {
    $(searchOpenBtn).removeClass('active');
    $(searchForm).removeClass('active');
  }
});
$('.home-carousel').on('init', ()=> {
  $('.home-carousel-dots .slick-dots').css({
    bottom: `${$('.home-carousel').outerHeight()/2}px`
  })
})
$('.home-carousel').slick({
  arrows: false,
  dots: true,
  fade: true,
  appendDots: $('.home-carousel-dots'),
  customPaging: (slider, i) => $('<button type="button" data-role="none" role="button" tabindex="0" />').text((i + 1) < 10 ? `0${i+1}.`: `${i+1}.`),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        adaptiveHeight: true
      }
    },
  ]
});
$('.sale-carousel').slick({
  slidesToShow: 4,
  variableWidth: true,
  swipeToSlide: true,
  prevArrow: `
    <div class="carousel-arrow carousel-arrow--prev">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="24.25" height="16.44" viewBox="0 0 24.25 16.44">
        <path fill="#000" fill-rule="evenodd" d="M0.311,8.998 L7.530,16.102 C7.971,16.536 8.685,16.536 9.126,16.102 C9.566,15.669 9.566,14.967 9.126,14.533 L3.832,9.324 L23.112,9.324 C23.734,9.324 24.239,8.826 24.239,8.213 C24.239,7.600 23.734,7.103 23.112,7.103 L3.832,7.103 L9.125,1.893 C9.566,1.461 9.566,0.757 9.125,0.324 C8.905,0.107 8.616,-0.001 8.328,-0.001 C8.039,-0.001 7.750,0.107 7.530,0.324 L0.311,7.428 C-0.129,7.862 -0.129,8.565 0.311,8.998 Z"/>
      </svg>
    </div>
  `,
  nextArrow: `
    <div class="carousel-arrow carousel-arrow--next">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="24.28" height="16.41" viewBox="0 0 24.28 16.41">
        <path fill="#000" fill-rule="evenodd" d="M23.938,7.410 L16.720,0.305 C16.279,-0.128 15.565,-0.128 15.124,0.305 C14.684,0.739 14.684,1.442 15.124,1.875 L20.418,7.085 L1.138,7.085 C0.515,7.085 0.010,7.582 0.010,8.195 C0.010,8.807 0.515,9.304 1.138,9.304 L20.418,9.304 L15.125,14.514 C14.684,14.948 14.684,15.651 15.125,16.084 C15.345,16.301 15.634,16.409 15.922,16.409 C16.211,16.409 16.500,16.301 16.720,16.084 L23.938,8.980 C24.379,8.546 24.379,7.843 23.938,7.410 Z"/>
      </svg>
    </div>
  `,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        variableWidth: false,
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 916,
      settings: {
        variableWidth: false,
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 688,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
      }
    },
  ]
});

$('.reviews-carousel').slick({
  slidesToShow: 4,
  variableWidth: true,
  swipeToSlide: true,
  prevArrow: `
    <div class="carousel-arrow carousel-arrow--reviews-prev">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="24.25" height="16.44" viewBox="0 0 24.25 16.44">
        <path fill="#000" fill-rule="evenodd" d="M0.311,8.998 L7.530,16.102 C7.971,16.536 8.685,16.536 9.126,16.102 C9.566,15.669 9.566,14.967 9.126,14.533 L3.832,9.324 L23.112,9.324 C23.734,9.324 24.239,8.826 24.239,8.213 C24.239,7.600 23.734,7.103 23.112,7.103 L3.832,7.103 L9.125,1.893 C9.566,1.461 9.566,0.757 9.125,0.324 C8.905,0.107 8.616,-0.001 8.328,-0.001 C8.039,-0.001 7.750,0.107 7.530,0.324 L0.311,7.428 C-0.129,7.862 -0.129,8.565 0.311,8.998 Z"/>
      </svg>
    </div>
  `,
  nextArrow: `
    <div class="carousel-arrow carousel-arrow--reviews-next">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="24.28" height="16.41" viewBox="0 0 24.28 16.41">
        <path fill="#000" fill-rule="evenodd" d="M23.938,7.410 L16.720,0.305 C16.279,-0.128 15.565,-0.128 15.124,0.305 C14.684,0.739 14.684,1.442 15.124,1.875 L20.418,7.085 L1.138,7.085 C0.515,7.085 0.010,7.582 0.010,8.195 C0.010,8.807 0.515,9.304 1.138,9.304 L20.418,9.304 L15.125,14.514 C14.684,14.948 14.684,15.651 15.125,16.084 C15.345,16.301 15.634,16.409 15.922,16.409 C16.211,16.409 16.500,16.301 16.720,16.084 L23.938,8.980 C24.379,8.546 24.379,7.843 23.938,7.410 Z"/>
      </svg>
    </div>
  `,
  responsive: [
    {
      breakpoint: 1609,
      settings: {
        variableWidth: false,
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        variableWidth: false,
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 688,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
      }
    },
  ]
});

let group; let src;
$(document.body).on('click', '.product-gallery__thumb', (e) => {
  e.preventDefault();
  const target = e.currentTarget;
  if ($(target).hasClass('active')) {
    return;
  }
  src = target.getAttribute('href');
  const loader = document.createElement('img');
  const img = document.querySelector('.product-gallery__image');
  $(target).parent().parent().find('.active').removeClass('active');
  $(target).addClass('active');
  loader.src = src;
  const a = document.querySelector('.product-gallery__output');
  loader.onload = () => {
    img.src = src;
    if (a.nodeName === 'A') {
      a.setAttribute('href', src);
    }
  }
});
(() => {
  const a = document.querySelector('.product-gallery__output');
  if (!a) return;
  if (a.nodeName === 'A') {
    const thumbs = [].slice.call(document.querySelectorAll('.product-gallery__thumb'));
    a.onclick = (ev) => {
      group = thumbs.map((el) => {
        return { href: el.getAttribute('href') };
      });
      const href = a.getAttribute('href')
      for (let i = 0; i < group.length; i++) {
        if (group[i].href === href) {
          group.splice(i, 1);
          i--;
        }
      }
      group.unshift({ href });
      ev.preventDefault();
      $.fancybox.open(
        group,
        {
          padding: 0,
          helpers: {
            overlay: {
              locked: false,
              css: {
                background: 'rgba(0,0,0,.65)'
              }
            }
          }
        }
      );
    }
  }
})();
$(document.body).on('click', '.show-more-link', (e) => {
  const target = e.currentTarget;
  $(target).toggleClass('active');
  $(target).prev().toggleClass('active');
  const text = $(target).text();
  $(target).text($(target).attr('data-toggle-text'))
  $(target).attr('data-toggle-text', text);
});
$('.testAjaxProduct').on('click', (e) => {
  e.stopPropagation();
  e.preventDefault();
  $.fancybox.open({
    href: '/ajax-quick-view.html',
    type: 'ajax'
  }, {
    padding: 0,

    helpers: {
      overlay: {
        locked: false,
        css: {
          'background': 'rgba(0,0,0,.65)'
        }
      }
    }
  });

})
$('.testAjaxReview').on('click', (e) => {
  e.stopPropagation();
  e.preventDefault();
  $.fancybox.open({
    href: '/ajax-review.html',
    type: 'ajax'
  }, {
    padding: 0,
    // scrolling: 'no'
    helpers: {
      overlay: {
        locked: false,
        css: {
          'background': 'rgba(0,0,0,.65)'
        }
      }
    }
  });

});
$('.faq-accordion__title').on('click', (e) => {
  const target = e.currentTarget;
  $('.faq-accordion').find('.active').removeClass('active');
  $(target).parent().addClass('active');
});
(() => {
  if (!document.getElementById('map')) {
    return ;
  };
  const center = {
    lat: 50.460501,
    lng: 30.642563
  };
  const map = new google.maps.Map(document.getElementById("map"), {
    center,
    zoom: 16,
    scrollwheel: false
  });
  const marker = new google.maps.Marker({
    position: center,
    map,
    icon: '/images/marker.png'
  });
})();

$(document.body).on('click', '.region', (e) => {
  const target = e.currentTarget;
  const points = JSON.parse(target.getAttribute('data-points'));
  const { places } = points;
  const coords = [];
  const addressesHTML = `
    ${places.map((place) => {
      const { shops } = place;
      return `
      <div class="address">
      <span class="address__city">${place.city}</span>
      ${shops.map((shop) => {
        const {
          lat, lng, address
        } = shop;
        coords.push({ lat, lng });
        return `<span class="address__place">${address}</span>`
      }).join('')}
      </div>
      `;
    }).join('')}
  `;
  const html = `
    <div class="contacts-modal">
        <div class="contacts-modal__title">${points.region}:</div>
        <div class="contacts-modal__row">
          <div class="contacts-modal__addresses">
            ${addressesHTML}
          </div>
          <div id="contacts-modal-map" class="contacts-modal__map"></div>
        </div>
      </div>
  `;
  $.fancybox.open({
    type: 'html',
    content: html
  }, {
    padding: 0,
    helpers: {
      overlay: {
        locked: false,
        css: {
          'background': 'rgba(0,0,0,.65)'
        }
      }
    },
    afterShow: function() {
      const mapEl = document.getElementById('contacts-modal-map');
      let bound = new google.maps.LatLngBounds();
      for (let i = 0; i < coords.length; i++) {
        bound.extend( new google.maps.LatLng(coords[i].lat, coords[i].lng));
      }
      const map = new google.maps.Map(mapEl, {
        center: bound.getCenter(),
        zoom: 9,
      });
      for (let i = 0; i < coords.length; i++) {
        new google.maps.Marker({
          position: coords[i],
          map,
          icon: '/images/marker.png'
        });
      }
    }
  });
});

$(window).on('load', () => {
  $('[data-row-name]').each((i, el) => {
    const type = $(el).attr('data-row-name');
    const height = $(`[data-row-type="${type}"]`).outerHeight();
    $(el).css('height', `${height}px`);
  });
  if ($('.compare-types').length) {
    $('.compare-types').css({
      top: $('.compare__table-inner table tr').eq(1).offset().top - $('.compare__table-inner').offset().top
    });
    if ($(window).width()<=1027) {
      jcf.destroy($('.jcf-scrollable'));
    } else {
      jcf.replaceAll();
    }
  }
});

$('.video-gallery').slick({
  slidesToShow: 2,
  swipe: false,
  // variableWidth: true,
  prevArrow: `
    <div class="carousel-arrow carousel-arrow--video-prev">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="24.25" height="16.44" viewBox="0 0 24.25 16.44">
        <path fill="#000" fill-rule="evenodd" d="M0.311,8.998 L7.530,16.102 C7.971,16.536 8.685,16.536 9.126,16.102 C9.566,15.669 9.566,14.967 9.126,14.533 L3.832,9.324 L23.112,9.324 C23.734,9.324 24.239,8.826 24.239,8.213 C24.239,7.600 23.734,7.103 23.112,7.103 L3.832,7.103 L9.125,1.893 C9.566,1.461 9.566,0.757 9.125,0.324 C8.905,0.107 8.616,-0.001 8.328,-0.001 C8.039,-0.001 7.750,0.107 7.530,0.324 L0.311,7.428 C-0.129,7.862 -0.129,8.565 0.311,8.998 Z"/>
      </svg>
    </div>
  `,
  nextArrow: `
    <div class="carousel-arrow carousel-arrow--video-next">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="24.28" height="16.41" viewBox="0 0 24.28 16.41">
        <path fill="#000" fill-rule="evenodd" d="M23.938,7.410 L16.720,0.305 C16.279,-0.128 15.565,-0.128 15.124,0.305 C14.684,0.739 14.684,1.442 15.124,1.875 L20.418,7.085 L1.138,7.085 C0.515,7.085 0.010,7.582 0.010,8.195 C0.010,8.807 0.515,9.304 1.138,9.304 L20.418,9.304 L15.125,14.514 C14.684,14.948 14.684,15.651 15.125,16.084 C15.345,16.301 15.634,16.409 15.922,16.409 C16.211,16.409 16.500,16.301 16.720,16.084 L23.938,8.980 C24.379,8.546 24.379,7.843 23.938,7.410 Z"/>
      </svg>
    </div>
  `,
  responsive: [
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});
const $grid = $('.gallery-thumbs').isotope({
  // options
  itemSelector: '.gallery-thumbs__item',
  masonry: {
    // columnWidth: 360,
    // gutter: 30
  }
});
if ($grid.length) {
  $('.gallery-tabs__item').eq(0).addClass('active');
  $('[data-filter-init]').on('click', (e) => {
    e.preventDefault();
    const target = e.currentTarget;
    $(target).parent().find('.active').removeClass('active');
    const filterProp = '' + target.getAttribute('data-filter-init');
    if (target.nodeName.toLowerCase() === 'a') {
      $(target).parent().parent().find('.active').removeClass('active')
      $('.gallery-tabs-toggle .btn').removeClass('active');
      $('.gallery-tabs-toggle .dropdown-list').removeClass('active');
    }
    $(target).addClass('active');

    $grid.isotope({
      filter: function() {
        if (filterProp==='all') {
          return true;
        }
        const itemFilterProp = '' + this.getAttribute('data-filter');
        return itemFilterProp === filterProp;
      }
    })
  });
}
window.showOrderSuccess = () => {
  $.fancybox.open($('#order-success'), {
    padding: 0,
    helpers: {
      overlay: {
        locked: false,
        css: {
          'background': 'rgba(0,0,0,.65)'
        }
      }
    }
  });
};

$('.product-trigger-modal-tabs__nav-item').eq(0).addClass('active');
$('.product-trigger-modal-content').eq(0).addClass('active');
$(document).on('click', '.product-trigger-modal-tabs__nav-item', ({ currentTarget }) => {
  if ($(currentTarget).hasClass('active')) {
    return;
  }
  $(currentTarget).parent().find('.active').removeClass('active');
  $('.product-trigger-modal-content.active').removeClass('active');
  let currentIndex;
  for (let i = 0; i < $('.product-trigger-modal-tabs__nav-item').length; i++) {
    let target = $('.product-trigger-modal-tabs__nav-item').get(i);
    if (currentTarget===target) {
      currentIndex = i;
      break;
    }
  }
  $('.product-trigger-modal-content').eq(currentIndex).addClass('active');
  $(currentTarget).addClass('active');
});
$(document).on('click', '.gallery-tabs-toggle .btn', ({ currentTarget }) => {
  $(currentTarget).toggleClass('active');
  $(currentTarget).next().toggleClass('active');
});


const $mobileControlEmiters = $('[data-mobile-control]');
const $mobileControlOutputs = $('[data-mobile-control-output]');
$mobileControlEmiters.on('click', ({ currentTarget }) => {
  const active = $('.header-mobile').find('.active').get(0);
  const controlOutput = $(`[data-mobile-control-output="${currentTarget.getAttribute('data-mobile-control')}"]`).get(0);
  if (active === currentTarget) {
    $(currentTarget).removeClass('active');
    $(controlOutput).removeClass('active');
  } else {
    $mobileControlOutputs.removeClass('active');
    $(active).removeClass('active');
    $(currentTarget).addClass('active');
    $(controlOutput).addClass('active');
    $(controlOutput).find('[type="text"], [type="email"], [type="tel"], [type="search"]').eq(0).focus();
  }
});
const hideMobileControls = function() {
  $mobileControlOutputs.removeClass('active');
  $mobileControlEmiters.removeClass('active');
}
$('.dropdown-list__show-submenu').on('click', (e) => {
  e.stopPropagation();
  e.preventDefault();
  const link = $(e.currentTarget).parent();
  if ($(link).hasClass('active')) {
    $(link).removeClass('active');
  } else {
    $(link).closest('.dropdown-list').find('.active').removeClass('active');
    $(link).addClass('active');
  }
});

$('[data-app="toggle-dashboard-aside"]').on('click', ({ currentTarget }) => {
  $(currentTarget).toggleClass('active');
  $('.aside--dashboard').toggleClass('active');
});

let currentWidth = window.innerWidth;
let footerIsMobile;
const calcFooterPlace = () => {
  const need = document.querySelector('.footer-bottom');
  if (!need) {
    return ;
  }
  const targetParent = document.querySelector('.footer-top__inner');
  const footer = document.querySelector('.footer');
  if (currentWidth<=950 && !footerIsMobile) {
    targetParent.insertBefore(need, targetParent.children[1]);
    footerIsMobile = true;
  } else if (currentWidth>950 && footerIsMobile){
    footer.appendChild(need);
    footerIsMobile = false;
  }
};
$mobileControlOutputs.on('click', '.mobile-control-output__close', hideMobileControls);
const resizeHandler = () => {
  if (currentWidth!==window.innerWidth) {
    $.fancybox.close();
    currentWidth=window.innerWidth;
  }
  if (window.innerWidth >= 1200) {
    hideMobileControls();
    $('.gallery-tabs__item').eq(0).click();
    // $('.aside--dashboard').removeClass('active');
    // $('[data-app="toggle-dashboard-aside"]').removeClass('active');
  }
  calcFooterPlace();
}
resizeHandler();
window.addEventListener('resize', resizeHandler);
$('[data-app="toggle-mobile-filter"]').on('click', ({ currentTarget }) => {
  $(currentTarget).toggleClass('active');
  $('.aside').toggleClass('active');
});
$('.filter__title').on('click', ({ currentTarget }) => {
  if (window.innerWidth>1220) {
    return ;
  }
  if ($(currentTarget).parent().hasClass('active')) {
    $(currentTarget).parent().removeClass('active');
    return ;
  } else {
    $('.filter .active').removeClass('active');
    $(currentTarget).parent().addClass('active');
  }
});

window.openSubscribeLangModal = (e) => {
  // const { currentTarget } = e;
  // const email = currentTarget.querySelector('input[type="email"]').value;
  // $('#modal-subscribe-lang').find('[name="email"]').val(email);
  $.fancybox.open($('#modal-subscribe-lang'), {
    padding: 0,
    helpers: {
      overlay: {
        locked: false,
        css: {
          background: 'rgba(0,0,0,.65)'
        }
      }
    }
  });
}
